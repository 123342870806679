/* eslint-disable ghost/ember/no-observers */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useViewport } from '../../hooks';

const handleVideoIntersection = entries => {
    entries.forEach(({ isIntersecting, target }) => {
        if (!isIntersecting) {
            target.pause();
        }
    });
}

const propTypes = {
    video: PropTypes.string.isRequired,
    videoMobile: PropTypes.string,
    subtitles: PropTypes.string,
    subtitlesMobile: PropTypes.string,
    poster: PropTypes.string,
    controls: PropTypes.bool,
    eagerLoading: PropTypes.bool,
    isDecorative: PropTypes.bool,
};

const defaultProps = {
    videoMobile: null,
    subtitles: null,
    subtitlesMobile: null,
    poster: null,
    controls: null,
    eagerLoading: null,
    isDecorative: false,
}

const Video = ({
    video,
    videoMobile,
    subtitles,
    subtitlesMobile,
    poster,
    eagerLoading,
    isDecorative,
    ...props
}) => {
    const { isSmallBreakpoint } = useViewport();

    // We want the video to pause itself when it leaves the viewport
    const videoRef = useRef(null);
    useEffect(() => {
        let observer;

        if (IntersectionObserver && props.controls) {
            // Offset the header height (2 x 64px) to exclude it from intersection zone
            observer = new IntersectionObserver(
                handleVideoIntersection,
                { rootMargin: '-128px 0px 0px 0px' }
            );

            if (videoRef.current) {
                observer.observe(videoRef.current);
            }
        }

        return () => {
            observer?.disconnect()
        }
    }, [ props.controls ]);

    /*
        Media queries on video sources exist but are not dynamic (the source
        doesn't change on resize) so we can't use them in our case with
        changing aspect-ratio.
        Using a <video> and switching the source isn't enough either, so we need
        the `key` prop to force the browser to change source
    */

    return (
        <video
            ref={videoRef}
            width='100%'
            playsInline
            poster={poster}
            {...props}
            {...(isDecorative && {
                autoPlay: true,
                muted: true,
                loop: true,
                playsInline: true,
                disableremoteplayback: "true",
            })}
            key={isSmallBreakpoint}
        >
            <source src={videoMobile && isSmallBreakpoint ? videoMobile : video} type='video/mp4' loading={eagerLoading ? 'eager' : null} />
            {
                subtitlesMobile && isSmallBreakpoint
                    ? <track src={subtitlesMobile} kind='subtitles' srcLang='en' label='English' default />
                    : subtitles && <track src={subtitles} kind='subtitles' srcLang='en' label='English' default />
            }
        </video>
    )
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;
