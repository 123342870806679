/* eslint-disable react/function-component-definition*/
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import styles from '../../pages/styles/techPages.module.scss';
import improve from '../../images/technology/qa/improve-qa-testing-with-automation.jpg';
import reliable from '../../images/technology/qa/reliable-performance-testing.jpg';
import testing from '../../images/technology/qa/testing-in-the-field.png';
import devices from '../../images/technology/qa/all-devices-welcome.jpg';
import benefits from '../../images/technology/qa/immediate-and-long-term-benefits.svg';

export default function MobileTabCardQTAs({ id }) {
    const { t } = useTranslation();
    const [ cardData, setCardData ] = useState(0);

    const cardsContent = [
        {
            id: 0,
            image: improve,
            title: t('siteMetadata.qaTestAutomation.improve'),
            text: t('siteMetadata.qaTestAutomation.improveText'),
            alt: 'Improve QA testing with automation'
        },
        {
            id: 1,
            image: reliable,
            title: t('siteMetadata.qaTestAutomation.reliable'),
            text: t('siteMetadata.qaTestAutomation.reliableText'),
            alt: 'Reliable performance testing'
        },
        {
            id: 2,
            image: testing,
            title: t('siteMetadata.qaTestAutomation.testing'),
            text: t('siteMetadata.qaTestAutomation.testingText'),
            alt: 'Testing in the field'
        },
        {
            id: 3,
            image: devices,
            title: t('siteMetadata.qaTestAutomation.allDevices'),
            text: t('siteMetadata.qaTestAutomation.allDevicesText'),
            alt: 'All devices welcome'
        },
        {
            id: 4,
            image: benefits,
            title: t('siteMetadata.qaTestAutomation.benefits'),
            text: t('siteMetadata.qaTestAutomation.benefitsText'),
            alt: 'Immediate and long-term benefits'
        }
    ];

    const handleClick = (name, e) => {
        setCardData(name);
    };

    return (
        <section id={styles.interactiveSection}>
            <h2 id={styles.interactiveHeaderQa}>
                {t('siteMetadata.qaTestAutomation.leave')}
            </h2>
            <div className={styles.interactiveSectionWrapper}>
                <ul id={styles.interactiveList}>
                    {
                        cardsContent.map(card => (
                            <li
                                key={card.id}
                                className={styles.interactiveItem}
                                onClick={(e) => handleClick(card.id, e)}
                            >
                                <div className={styles.chevronButton}>
                                    <FontAwesomeIcon
                                        icon={cardData === card.id ? faChevronDown : faChevronRight}
                                    />
                                </div>
                                <div className={styles.cardText}>
                                    <h3 className={styles.cardTitle}>
                                        {card.title}
                                    </h3>
                                    <p className={styles.cardParagraph}>
                                        <p className={cardData === card.id ? styles.cardVisible : styles.cardInvisible}>
                                            {card.text}
                                        </p>
                                    </p>
                                </div>
                                <div className={styles.interactiveImage}>
                                    <img src={card.image} className={cardData === card.id ? styles.imageVisible : styles.imageInvisible} alt={card.alt} />
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </section>
    );
}